import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiPaths } from '@/constants/api-paths';
import { formatDate } from '@/helpers/format-date';
import { api } from '@/lib/api';
import { createApiResponseSchema } from '@/schemas/api-response-schema';

import { useAppSelector } from './use-app-selector';

export const BatchMetafieldSchema = z.object({
  id: z.string().uuid(),
  value: z.object({
    value: z.string(), // Allow for a nested object with a value field
  }),
  metafield: z.object({
    id: z.string().uuid(),
    key: z.string(),
    type: z.string(),
    listOptions: z.array(z.string()).nullable().optional(),
    isFavorite: z.boolean(),
    createdAt: z.string().transform(formatDate),
    updatedAt: z.string().transform(formatDate),
  }),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
});

export type BatchMetafield = z.infer<typeof BatchMetafieldSchema>;

const BatchSchema = z.object({
  id: z.string().uuid(),
  lot: z.string(),
  expiresAt: z.string().transform(formatDate).nullable(),
  producedAt: z.string().transform(formatDate).nullable(),
  tags: z.array(z.string()).optional().nullable(),
  brandId: z.string().uuid(),
  productId: z.string().uuid(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),

  product: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
      brandId: z.string().uuid(),
      sku: z.string(),
      image: z.string().optional(),
      imageUrl: z.string().optional(),
      templateId: z.string().uuid().nullable().optional(),
      createdAt: z.string().transform(formatDate),
      updatedAt: z.string().transform(formatDate),
    })
    .nullable()
    .optional(),

  batchMetafields: z.array(BatchMetafieldSchema).nullable().optional(),

  tidCount: z.number().int().optional(),
});

const BatchListApiResponseSchema = createApiResponseSchema(BatchSchema);

async function getBatch({
  batchId,
  brandId,
  productId,
}: {
  batchId: string;
  brandId: string;
  productId: string;
}) {
  const response = await api.get(
    apiPaths.BATCH_BY_ID_NESTED({ brandId, productId, batchId }),
  );

  const { data } = BatchListApiResponseSchema.parse(response.data);

  return {
    batch: data,
  };
}

export function useBatchQuery({
  productId,
  batchId,
}: {
  productId?: string;
  batchId: string;
}) {
  const brandId = useAppSelector((state) => state.brands.selected?.id);

  return useQuery({
    queryKey: ['batch', brandId, productId, batchId],
    queryFn: () =>
      getBatch({ brandId: brandId ?? '', productId: productId ?? '', batchId }),
    enabled: Boolean(brandId) && Boolean(productId) && Boolean(batchId),
  });
}
