import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

const labelLookup: Record<string, string> = {
  string: 'Single Line Text',
  number: 'Number',
  list: 'List',
  boolean: 'True/False',
};

export const MetafieldTypeSchema = z.union([
  z.literal('string'),
  z.literal('number'),
  z.literal('boolean'),
  z.literal('list'),
]);

export const MetafieldSchema = z.object({
  id: z.string(),
  key: z.string(),
  type: MetafieldTypeSchema.transform((type) => labelLookup[type] || type),
  listOptions: z.string().array().nullable(),
  isFavorite: z.boolean(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
});

export type Metafield = z.infer<typeof MetafieldSchema>;

export type MetafieldRequestParams = Omit<
  Metafield,
  'id' | 'createdAt' | 'updatedAt'
>;
