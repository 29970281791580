import { HeartIcon, HeartOffIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import type { Metafield } from '@/schemas/batch-nested';

interface MetafieldsListProps {
  metafields?: Metafield[];
  isLoading?: boolean;
  onToggleFavorite: (data: {
    metafieldId: string;
    isFavorite: boolean;
  }) => void;
}

export function MetafieldsList({
  metafields,
  isLoading,
  onToggleFavorite,
}: MetafieldsListProps) {
  if (!isLoading && !metafields?.length) {
    return null;
  }

  if (isLoading) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            {/* <TableHead>Namespace</TableHead> */}
            <TableHead>Favorite</TableHead>
            <TableHead>Key</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>List Options</TableHead>
            {/* <TableHead>Actions</TableHead> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(3)].map((_, index) => (
            <TableRow key={index}>
              {/* <TableCell>
                <Skeleton className="h-4 w-20" />
              </TableCell> */}
              <TableCell>
                <Skeleton className="h-4 w-4 rounded-full" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-24" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-16" />
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-32" />
              </TableCell>
              {/* <TableCell>
                <div className="flex space-x-2">
                  <Skeleton className="h-8 w-8 rounded" />
                  <Skeleton className="h-8 w-8 rounded" />
                </div>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          {/* <TableHead>Namespace</TableHead> */}
          <TableHead>Favorite</TableHead>
          <TableHead>Key</TableHead>
          <TableHead>Type</TableHead>
          <TableHead>List Options</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {metafields?.map((metafield) => (
          <TableRow key={metafield.id}>
            {/* <TableCell>{metafield.namespace.name}</TableCell> */}
            <TableCell>
              <Button
                onClick={() => {
                  onToggleFavorite({
                    metafieldId: metafield.id,
                    isFavorite: !metafield.isFavorite,
                  });
                }}
                size="icon"
                variant="ghost"
              >
                {metafield.isFavorite ? (
                  <HeartIcon className="h-4 w-4 text-red-400" />
                ) : (
                  <HeartOffIcon className="h-4 w-4" />
                )}
              </Button>
            </TableCell>
            <TableCell>{metafield.key}</TableCell>
            <TableCell>{metafield.type}</TableCell>
            <TableCell>
              {metafield.type === 'List' && metafield.listOptions
                ? metafield.listOptions.join(', ')
                : '-'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
