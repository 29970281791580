const srcScanMap = {
  prod: 'https://analytics.zoho.com/open-view/2185350000007001026/46673cea0fc0904d6db0a1d345aaba5e',
  demo: 'https://analytics.zoho.com/open-view/2185350000008992636/639e0366019ef55e62dbf37a942a8637',
};

export type Env = keyof typeof srcScanMap;

export function ScanMap({ env, brandId }: { env: Env; brandId?: string }) {
  const query = brandId
    ? `?ZOHO_CRITERIA=%22Scans%22.%22brandId%22%20%3D%20'${brandId}'`
    : undefined;

  const src = srcScanMap[env] || srcScanMap.prod + query;

  return <iframe height={640} src={src} title="scan-map" width="100%" />;
}
