import { Label, Pie, PieChart, PolarRadiusAxis, RadialBar, RadialBarChart } from "recharts"

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from "@/components/ui/chart"
import { cn } from "@/lib/utils"
import { Separator } from "../ui/separator"
import seals from "@/assets/seals.png"
import seals1 from "@/assets/seals-1.png"
import seals2 from "@/assets/seals-2.png"
import seals3 from "@/assets/seals-3.png"
import seals4 from "@/assets/seals-4.png"

const esgChartData = [
  { browser: "chrome", visitors: 275, fill: "var(--color-chrome)" },
  { browser: "safari", visitors: 200, fill: "var(--color-safari)" },
  { browser: "firefox", visitors: 187, fill: "var(--color-firefox)" },
  { browser: "edge", visitors: 173, fill: "var(--color-edge)" },
  { browser: "other", visitors: 90, fill: "var(--color-other)" },
]
const esgChartConfig = {
  visitors: {
    label: "Visitors",
  },
  chrome: {
    label: "Recycled Plastic",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Bamboo",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Recycled Metal",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Recycled Glass",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Organic Token",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

const radialChartData = [{ month: "january", poor: 50, average: 10, good: 10, excelent: 30 }]

const radialChartConfig = {
  poor: {
    label: "Poor",
    color: "hsl(var(--chart-1))",
  },
  average: {
    label: "Average",
    color: "hsl(var(--chart-5))",
  },
  good: {
    label: "Good",
    color: "hsl(var(--chart-4))",
  },
  excelent: {
    label: "Excelent",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig

export function EsgCard({className}: {className?: string}) {
  return (
    <Card className={cn("flex flex-col", className)}>
      <CardHeader className="pb-0">
        <CardTitle className="text-zinc-500">ESG Score</CardTitle>
      </CardHeader>
      <CardContent className="flex items-center pb-0 flex-col">
        <ChartContainer
          config={radialChartConfig}
          className="mx-auto aspect-square w-full max-w-[250px]"
        >
          <RadialBarChart
            data={radialChartData}
            endAngle={180}
            innerRadius={80}
            outerRadius={130}
          >
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) - 16}
                          className="fill-foreground text-2xl font-bold"
                        >
                          88
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 4}
                          className="fill-muted-foreground"
                        >
                          Excelent
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </PolarRadiusAxis>
            <RadialBar
              dataKey="excelent"
              fill="var(--color-excelent)"
              stackId="a"
              cornerRadius={5}
              className="stroke-transparent stroke-2"
            />
            <RadialBar
              dataKey="good"
              stackId="a"
              cornerRadius={5}
              fill="var(--color-good)"
              className="stroke-transparent stroke-2"
            />
            <RadialBar
              dataKey="average"
              fill="var(--color-average)"
              stackId="a"
              cornerRadius={5}
              className="stroke-transparent stroke-2"
            />
            <RadialBar
              dataKey="poor"
              stackId="a"
              cornerRadius={5}
              fill="var(--color-poor)"
              className="stroke-transparent stroke-2"
            />
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 p-6 text-sm">
        <CardTitle className="text-zinc-500">Certifications</CardTitle>
        <div className="flex justify-between w-full">
          <img src={seals} alt="Seals" className="w-6 h-6" />
          <img src={seals1} alt="Seals 1" className="w-6 h-6" />
          <img src={seals2} alt="Seals 2" className="w-6 h-6" />
          <img src={seals3} alt="Seals 3" className="w-6 h-6" />
          <img src={seals4} alt="Seals 4" className="w-6 h-6" />
        </div>
      </CardFooter>
      <Separator className="m-2" />
      <CardHeader className="pb-0">
        <CardTitle className="text-zinc-500">Materials</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={esgChartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip

              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie data={esgChartData} dataKey="visitors" nameKey="browser" />
            <ChartLegend
              content={<ChartLegendContent nameKey="browser" />}
              className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

