// import { z } from 'zod';

// import { alertColumns } from '@/components/alert-columns';
// import { DataTable as AlertDataTable } from '@/components/alert-table';
import type { Env } from '@/components/scan-map';
import { ScanMap } from '@/components/scan-map';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
// import { alertSchema } from '@/data/alert-schema';
// import alertData from '@/data/alerts.json';
import { useAppSelector } from '@/hooks/use-app-selector';

import { Charts } from './charts';

// function getAlerts() {
//   return z.array(alertSchema).parse(alertData);
// }

export function Analytics() {
  const selectedBrandId = useAppSelector((state) => state.brands.selected)?.id;
  // const alerts = getAlerts();
  const env = import.meta.env.VITE_ENVIRONMENT as Env;

  const tabs = [
    {
      value: 'map',
      label: 'Map',
      content: <ScanMap brandId={selectedBrandId} env={env} />,
      disabled: false,
    },
    {
      value: 'analytics',
      label: 'Analytics',
      content: <Charts brandId={selectedBrandId} env={env} />,
      disabled: false,
    },
    {
      value: 'alerts',
      label: 'Alerts',

      content: (
        <div>
          <iframe
            height={320}
            src="https://analytics.zoho.com/open-view/2185350000007294485"
            title="alert-counterfeit"
            width="100%"
          />
          <iframe
            height={320}
            src="https://analytics.zoho.com/open-view/2185350000007294148"
            title="alert-consumer"
            width="100%"
          />
        </div>
      ),
      disabled: false,
    },
  ];

  return (
    <Tabs className="space-y-4" defaultValue="map">
      <TabsList>
        {tabs.map((tab) => (
          <TabsTrigger
            disabled={tab.disabled}
            key={tab.value}
            value={tab.value}
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map((tab) => (
        <TabsContent className="space-y-4" key={tab.value} value={tab.value}>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
            <div className="col-span-7">{tab.content}</div>
          </div>
        </TabsContent>
      ))}
    </Tabs>
  );
}
