import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ShortenedUUID } from '@/components/shortened-uuid';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { getMetafields } from '@/helpers/get-metafields';
import { useScanQuery } from '@/hooks/use-scan-query';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { useTidQuery } from '@/hooks/use-tid-query';
import { scanColumns } from '@/modules/scans/components/data-table/scan-columns';
import { DataTable } from '@/modules/scans/components/data-table/scan-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';
import Map from '@/components/dpp-map';
import { JourneySteps } from '@/components/journey-steps';
import { NfcIcon } from 'lucide-react';

export function Tid() {
  const { tidId } = useParams() as { tidId: string };
  const filters = useSearchFilters();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const tidQuery = useTidQuery(tidId);

  const scanQuery = useScanQuery({
    filters: columnFilters,
    tidId,
    pagination: {
      take: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
  });

  useEffect(() => {
    setColumnFilters(filters);
  }, [filters]);

  const metafields = getMetafields(tidQuery.data?.tid.batch.batchMetafields);

  return (
    <>
      <div className="grid gap-4 lg:grid-cols-6">
        <div className="col-span-2">
          <Card className="flex flex-col gap-4 h-[480px]">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-6 bg-muted">
              <CardTitle className="text-base font-medium w-full flex gap-4 justify-between items-center">
                <div className="grow shrink basis-0 justify-start items-start gap-4 inline-flex">
                    {/* 
                  <div className='flex justify-between w-full'>
                    
                    <div className="flex items-center gap-1 text-muted-foreground">
                      <span className="text-muted-foreground">Total Scans</span>
                      <NfcIcon /> 
                      {scanQuery.data?.pagination?.total}
                    </div> 
                  </div>
                    */}
                  <div className="bg-white rounded-lg shadow-sm">
                    <img src={tidQuery.data?.tid.batch.product.imageUrl ?? ''} alt={tidQuery.data?.tid.batch.product.name} className="w-20 h-20 object-cover rounded-lg" />
                  </div>
                  <div className='grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex'>
                    <div className="self-stretch text-zinc-950 text-lg font-semibold capitalize leading-7 flex items-center justify-between">
                      {tidQuery.data?.tid.batch.product.name}
                    </div>
                    <div className="self-stretch justify-start items-start gap-1 inline-flex">
                      <div className="text-zinc-500 text-sm font-normal leading-tight">Created at:</div>
                      <div className="text-zinc-500 text-sm font-normal leading-tight">{tidQuery.data?.tid.createdAt}</div>
                    </div>
                    <div className="self-stretch flex justify-between">
                      <div className="self-stretch justify-start items-start gap-1 inline-flex">
                        <div className="text-zinc-500 text-sm font-normal leading-tight">SKU:</div>
                        <div className="text-zinc-500 text-sm font-normal leading-tight">{tidQuery.data?.tid.batch.product.sku}</div>
                      </div>
                      <Badge variant="success">{tidQuery.data?.tid.isActive ? 'Active' : 'Inactive'}</Badge>
                    </div>
                  </div>
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent className="w-full flex flex-col gap-4">
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Batch Id:</span>
                <ShortenedUUID
                  copyHidden
                  uuid={tidQuery.data?.tid.batchId ?? ''}
                />
              </p>
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Lot:</span>
                {tidQuery.data?.tid.batch.lot ?? ''}
              </p>
              {tidQuery.data?.tid.batch.producedAt ? (
                <p className="w-full flex gap-4 justify-between">
                  <span className="text-muted-foreground">Produced at:</span>
                  {tidQuery.data.tid.batch.producedAt}
                </p>
              ) : null}
              {tidQuery.data?.tid.batch.expiresAt ? (
                <p className="w-full flex gap-4 justify-between">
                  <span className="text-muted-foreground">Expires at:</span>
                  {tidQuery.data.tid.batch.expiresAt}
                </p>
              ) : null}
              {metafields.map((field) => (
                <p className="w-full flex gap-4 justify-between" key={field.key}>
                  <span className="text-muted-foreground">{field.key}:</span>
                  {field.value}
                </p>
              ))}
              {tidQuery.data?.tid.batch.tags?.length ? (
                <div className="flex flex-col gap-2">
                  <p className="w-full flex gap-4 justify-between">
                    <span className="text-muted-foreground">Tags:</span>
                  </p>
                  <div className="flex flex-wrap gap-1">
                    {tidQuery.data.tid.batch.tags.map((tag) => (
                      <Badge key={tag} variant="secondary">
                        {tag}
                      </Badge>
                    ))}
                  </div>
                </div>
              ) : null}
            </CardContent>
          </Card>
        </div>
          <Card className="flex gap-4 overflow-hidden col-span-4 h-[480px]">
            <JourneySteps tidId={tidQuery.data?.tid.id} />
            <Map journeyLocations={[
              { name: "Production Facility", coordinates: [-117.9, 33.9] },
              { name: "Distribution Center", coordinates: [-84.4, 33.7] },
              { name: "Departed Facility in New York", coordinates: [-74.0, 40.7] },
              { name: "Customer Received", coordinates: [2.29, 48.83] }
            ]} />
          </Card>
      </div>
      <Card>
        <DataTable
          columnFilters={columnFilters}
          columns={scanColumns}
          data={scanQuery.data?.scans ?? []}
          initialColumnVisibility={{
            tidId: false,
            productName: false,
            view: false,
          }}
          onColumnFiltersChange={setColumnFilters}
          onPaginationChange={setPagination}
          pagination={pagination}
          rowCount={scanQuery.data?.pagination?.total ?? 0}
          showToolbar={false}
        />
      </Card>
    </>
  );
}
