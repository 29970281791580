import { Env } from '@/components/scan-map';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

const scanByDay = {
  prod: 'https://analytics.zoho.com/open-view/2185350000007001706/bb4277bab7a2138288dc371216a542ea',
  demo: 'https://analytics.zoho.com/open-view/2185350000009418328/2fc7d7dfbc0a21ba208b741e81606c3f',
};

const scanByProduct = {
  prod: 'https://analytics.zoho.com/open-view/2185350000007001459/9c6da499ba0bb4788a0ed4f609ec5641',
  demo: 'https://analytics.zoho.com/open-view/2185350000009496143/515c8b728987bc53cae397c312876965',
};

const scanByCountry = {
  prod: 'https://analytics.zoho.com/open-view/2185350000007001334/99fb94b1d2be739e00292ec8d76ae889',
  demo: 'https://analytics.zoho.com/open-view/2185350000009212377/11caab27ece5fd15ada68b2f670ef25e',
};

const topScanningCities = {
  prod: 'https://analytics.zoho.com/open-view/2185350000007001989/59cc29b3df766587665b825850caea13',
  demo: 'https://analytics.zoho.com/open-view/2185350000008992957/bd51ed215fc218efa0aa6145d8422c95',
};

function getSrc({ brandId, url } : { brandId?: string, url: string }) {
  const query = brandId
    ? `?ZOHO_CRITERIA=%22Scans%22.%22brandId%22%20%3D%20'${brandId}'`
    : undefined;

  return `${url}${query}`;
}



export function Charts({ env, brandId }: { env: Env; brandId?: string }) {
  const scanByDaySrc = getSrc({ brandId, url: scanByDay[env] });
  const scanByProductSrc = getSrc({ brandId, url: scanByProduct[env] });
  const scanByCountrySrc = getSrc({ brandId, url: scanByCountry[env] });
  const topScanningCitiesSrc = getSrc({ brandId, url: topScanningCities[env] });

  console.log(scanByCountrySrc);

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-12">
      <Card className="col-span-12">
        <CardHeader>
          <CardTitle>Scans Overtime</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={170}
            src={scanByDaySrc}
            title="scan-by-day"
            width="100%"
          />
        </CardContent>
      </Card>
      <Card className="col-span-6">
        <CardHeader>
          <CardTitle>Scans by Product</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={255}
            src={scanByProductSrc}
            title="scan-by-product"
            width="100%"
          />
        </CardContent>
      </Card>
      <Card className="col-span-3">
        <CardHeader>
          <CardTitle>Scans by Country</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={255}
            src={scanByCountrySrc}
            title="scan-by-country"
            width="100%"
          />
        </CardContent>
      </Card>
      <Card className="col-span-3">
        <CardHeader>
          <CardTitle>Top Scanning Cities</CardTitle>
        </CardHeader>
        <CardContent className="pl-2">
          <iframe
            height={255}
            src={topScanningCitiesSrc}
            title="top-scanning-cities"
            width="100%"
          />
        </CardContent>
      </Card>
    </div>
  );
}
