import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';



const typeOptions = [
  { label:'Single Text', value:'string'}, 
  { label:'Number', value:'number' }, 
  { label: 'True/False', value:'boolean' }, 
  { label:'List', value:'list' }
];

// I wanna extract values from typeOption and create an enum.
const typeValues = typeOptions.map(({value}) => value) as [string, ...string[]];

const schema = z.object({
  id: z.string().optional(),
  namespaceId: z.string(),
  key: z.string().min(3, 'Key is required'),
  type: z.enum(typeValues),
  isFavorite: z.boolean(),
  listOptions: z.string().optional(),
});

export type MetafieldFormData = z.infer<typeof schema>;

interface MetafieldFormProps {
  onSubmit: (data: MetafieldFormData) => void | Promise<void>;
  values?: MetafieldFormData;
  editingId?: string;
}

export function MetafieldForm({
  onSubmit,
  values,
  editingId,
}: MetafieldFormProps) {
  const { control, handleSubmit, watch } = useForm<MetafieldFormData>({
    resolver: zodResolver(schema),
    values,
  });

  const watchType = watch('type');

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* <div className="space-y-2">
            <Label htmlFor="namespace">Namespace</Label>
            <Controller
              control={control}
              name="namespaceId"
              render={({ field }) => (
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select namespace" />
                  </SelectTrigger>
                  <SelectContent>
                    {namespacesQuery.data?.namespaces.map((option) => (
                      <SelectItem key={option.id} value={option.id}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div> */}
        <div className="space-y-2">
          <Label htmlFor="key">Key</Label>
          <Controller
            control={control}
            name="key"
            render={({ field }) => <Input {...field} id="key" />}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="type">Type</Label>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  {typeOptions.map(({label, value}) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>
        {watchType === 'list' && (
          <div className="space-y-2">
            <Label htmlFor="listOptions">List Options (comma-separated)</Label>
            <Controller
              control={control}
              name="listOptions"
              render={({ field }) => (
                <Input
                  {...field}
                  id="listOptions"
                  placeholder="Option1, Option2, Option3"
                />
              )}
            />
          </div>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <Controller
          control={control}
          name="isFavorite"
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              id="isFavorite"
              onCheckedChange={field.onChange}
            />
          )}
        />
        <Label htmlFor="isFavorite">Favorite</Label>
      </div>
      <Button type="submit">{editingId ? 'Update' : 'Add'} Metafield</Button>
    </form>
  );
}
