import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_ACCESS_TOKEN } from '@/constants/env';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

interface Location {
  name: string;
  coordinates: [number, number];
}

interface MapProps {
  journeyLocations: Location[];
}

const DppMap = ({ journeyLocations }: MapProps) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    if (map.current) return; // Initialize map only once
    if (!mapContainer.current) return; // wait for map container to be available

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-20.5, 48],
      zoom: 1
    });

    map.current.on('load', () => {
      setIsMapLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!isMapLoaded || !map.current) return;

    // Remove existing markers and route
    document.querySelectorAll('.marker').forEach(el => el.remove());
    if (map.current?.getSource('route')) {
      map.current.removeLayer('route');
      map.current.removeSource('route');
    }

    addMarkersAndRoute(map.current);
  }, [journeyLocations, isMapLoaded]);

  const addMarkersAndRoute = (mapInstance: mapboxgl.Map) => {
    // Add markers for each location
    journeyLocations.forEach((location, index) => {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundColor = index === journeyLocations.length - 1 ? '#10B981' : '#6366F1';
      el.style.width = '24px';
      el.style.height = '24px';
      el.style.borderRadius = '50%';
      el.style.display = 'flex';
      el.style.justifyContent = 'center';
      el.style.alignItems = 'center';
      el.style.color = 'white';
      el.style.fontSize = '14px';
      el.style.fontWeight = 'bold';
      el.innerText = (index + 1).toString();

      new mapboxgl.Marker(el)
        .setLngLat(location.coordinates)
        .setPopup(new mapboxgl.Popup().setHTML(`<h3>${location.name}</h3>`))
        .addTo(mapInstance);
    });

    // Add a line connecting the markers
    mapInstance.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: journeyLocations.map(loc => loc.coordinates)
        }
      }
    });

    mapInstance.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#6366F1',
        'line-width': 3
      }
    });
  };

  return <div ref={mapContainer} className="w-full h-full absolute inset-0" />;
};

export default DppMap;

