import { TrendingUp } from "lucide-react"
import { Pie, PieChart } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"
import { cn } from "@/lib/utils"
import { Separator } from "../ui/separator"
const chartData = [
  { browser: "chrome", visitors: 275, fill: "var(--color-chrome)" },
  { browser: "safari", visitors: 200, fill: "var(--color-safari)" },
  { browser: "firefox", visitors: 187, fill: "var(--color-firefox)" },
  { browser: "edge", visitors: 173, fill: "var(--color-edge)" },
  { browser: "other", visitors: 90, fill: "var(--color-other)" },
]

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  chrome: {
    label: "Chrome",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Safari",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Firefox",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Edge",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

export function CarbonFootprint({className}: {className?: string}) {
  return (
    <Card className={cn("flex flex-col gap-4", className)}>
      <CardHeader className="pb-0 text-zinc-500">
        <CardTitle>Carbon Footprint</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={chartData}
              dataKey="visitors"
              nameKey="browser"
              innerRadius={60}
            />
          </PieChart>
        </ChartContainer>
        <div className="self-stretch h-10 flex-col justify-start items-start flex">
          <div className="text-zinc-500 text-sm font-normal leading-tight">Emissions per product</div>
          <div className="text-zinc-950 text-sm font-normal leading-tight">5 kg CO₂</div>
        </div>
        <div className="self-stretch h-10 flex-col justify-start items-start flex">
          <div className="text-zinc-500 text-sm font-normal leading-tight">Total CO2 emissions (per year)</div>
          <div className="text-zinc-950 text-sm font-normal leading-tight">5,000 kg</div>
        </div>
        <div className="self-stretch h-10 flex-col justify-start items-start flex">
          <div className="text-zinc-500 text-sm font-normal leading-tight">Total miles travelled</div>
          <div className="text-zinc-950 text-sm font-normal leading-tight">15,000 miles</div>
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="flex-col gap-2 text-sm">
      <div className="self-stretch text-zinc-500 text-sm font-normal leading-tight">Manufacturer: </div>
        <div className="self-stretch justify-start items-start gap-2 inline-flex">
          <img className="w-12 h-6 rounded-full border border-zinc-200" src="https://forgestop-assets-bucket-public-demo.s3.amazonaws.com/1732159448314_1a6be365-0bc0-4fa5-bfbc-a26ec687e0bf_ACME-logo.png" />
          <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex">
            <div className="self-stretch text-zinc-950 text-sm font-normal leading-tight">AcmeMaterials Inc.</div>
            <div className="self-stretch text-zinc-500 text-xs font-normal leading-3">123 Sustainability Avenue, Green City, CA, USA.</div>
            <div className="self-stretch text-zinc-500 text-sm font-normal underline leading-tight">www.acme.inc</div>
          </div>
        </div>
      </CardFooter>
    </Card>
  )
}
